import { type } from "@testing-library/user-event/dist/type";
import React, { useState, useEffect } from "react";


export default function TideIcon() {
  let [tides, setTides] = useState(null);

  //3 create our useEffect function
  useEffect(() => {
    const dates = getDates()
    function fetchTides() {
      //let date = getDate()
      return (
        fetch(
          //TODO, Tides are currently hardcoded for the date. Neet to set URL params based on
          `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=predictions&application=NOS.COOPS.TAC.WL&begin_date=${dates.today}&end_date=${dates.tomorrow}&datum=MLLW&station=8518924&time_zone=lst_ldt&units=english&interval=hilo&format=json`
        )
          .then((response) => response.json())
          //4. setting *dogImage to the image url that we received from the response above
          .then((data) => {
            if (data.predictions) {
              setTides(data.predictions);
            }
          })
          .catch((e) => {
            console.log(
              "ok there was an error with the tides data fetch, catching error here"
            );
            console.error(e);
          })
      );
    }

    fetchTides();

    const intervalId = setInterval(() => {
      fetchTides();
    }, 111000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (tides != null && tides != undefined) {
    const now = new Date();
    //Go through the array of tide information and format it nicely. Finally, get rid of tides that have already passed
    for (let i = 0; i < tides.length; i++) {
      let tideDate = new Date(tides[i].t);
      if(tideDate.getTime() <= now.getTime()){
        tides.splice(i,1);
      }
    for (let i = 0; i < tides.length; i++){
      tides[i].type = letterToWord(tides[i].type);

      //formatting the date for this object
      const split = tides[i].t.split(" ");
      let d = split[0].split("-");
      tides[i].date = d[1].replace(/^0+/, "") + "/" + d[2]; //trim leading zeros from month, remove year
      tides[i].time = split[1]//.replace(/^0/, ""); //trim leading zeros
      //if tide has passed, bump it from the array
      }
    }

    return (
        <div className="utility-icon">
          <h4>Next Tide</h4>
          {getTideImg(tides[0].type)}
          <h4>{tides[0].type} {tides[0].time}</h4>
        </div>
    );
  } 
  else
    return (
      <div>
        Tides Data Failed to Load, check internet connection and reload page
      </div>
    );
}

//functions go here
function getDates(){
    let today = new Date();
    let tomorrow = new Date(Date.now() + (3600 * 1000 * 48)) 
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let dd = String(today.getDate()).padStart(2, '0');
    let yyyy2 = tomorrow.getFullYear();
    let mm2 = String(tomorrow.getMonth()+1).padStart(2, '0');
    let dd2 = String(tomorrow.getDate()).padStart(2,'0');
  return {today:yyyy+mm+dd,tomorrow:yyyy2+mm2+dd2};
}

function getTideImg(word){
  if(word == "Low"){
    return(<img height="80px" alt="" src="/lowTide.svg" />)
  }else if (word =="High"){
    return(<img height="80px" alt="" src="/highTide.svg" />)
  }else return ("no tide image");
}

function letterToWord(type) {
  if (type == "L") {
    return "Low";
  } else if (type == "H") {
    return "High";
  } else return type;
}

function table(tides){
  var table = [];
  for(let i = 0; i<tides.length-5;i++){
    var tideDate = new Date(tides[i].t);
    table.push(
    <tr>
    <td>{tides[i].date}</td>
    <td>{tides[i].time}</td>
    <td>{tides[i].type}</td>
    <td>{tides[i].v}</td>
    </tr>)
  }
  return(
    <React.Fragment>
    {table}
    </React.Fragment>
  )
}

