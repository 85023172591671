import {useState, useEffect, React} from 'react'

export default function ClockComponent(){
    const [d,setD]=useState(new Date());
    useEffect(()=>{
        
        const intervalId = setInterval(()=>{
            setD(new Date());
        }, 1000)

        return () => clearInterval(intervalId);
    },[])

    return (
      <span className='clock'>
        {d.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12:false})}
      </span>
    );
}