import {useEffect, useState, React} from 'react'

export default function RadarComponent() {
    const [radarImg,setRadarImg]=useState("https://radar.weather.gov/ridge/standard/NORTHEAST_loop.gif");
    useEffect(()=>{
        
        const intervalId = setInterval(()=>{
            const i = "https://radar.weather.gov/ridge/standard/NORTHEAST_loop.gif?v=" + Math.random()
            setRadarImg(i);
        }, 60000)

        return () => clearInterval(intervalId);
    },[])

    return (
        <div className="radar-box">
          <h1>Northeast Radar</h1>
          <div className="radar-container">
            <img className="radar-gif" alt="radar gif" src={radarImg} />
            <div className="overlay">
              <div className="dot">
                <div className="dot-center"></div>
              </div>
              <div className="label">Our Location</div>
            </div>
          </div>
        </div>
      );
    }