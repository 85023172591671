import React, { Component } from "react";

export default function MoonIcon() {
    const getJulianDate = (date = new Date()) => {
        const time = date.getTime();
        const tzoffset = date.getTimezoneOffset();
        return time / 86400000 - tzoffset / 1440 + 2440587.5;
    };
    const LUNAR_MONTH = 29.530588853;
    const getLunarAge = (date = new Date()) => {
        const percent = getLunarAgePercent(date);
        const age = percent * LUNAR_MONTH;
        return age;
    };
    const getLunarAgePercent = (date = new Date()) => {
        return normalize((getJulianDate(date) - 2451550.1) / LUNAR_MONTH);
    };
    const normalize = (value) => {
        value = value - Math.floor(value);
        if (value < 0) value = value + 1;
        return value;
    };
    const getLunarPhase = (date = new Date()) => {
        const age = getLunarAge(date);
        if (age < 1.84566) return "New Moon";
        else if (age < 5.53699) return "Waxing Crescent";
        else if (age < 9.22831) return "First Quarter";
        else if (age < 12.91963) return "Waxing Gibbous";
        else if (age < 16.61096) return "Full Moon";
        else if (age < 20.30228) return "Waning Gibbous";
        else if (age < 23.99361) return "Last Quarter";
        else if (age < 27.68493) return "Waning Crescent";
        return "New Moon";
    };
    const getLunarEmoji = (date = new Date()) => {
        const age = getLunarAge(date);
        if (age < 1.84566) return "🌑";
        else if (age < 5.53699) return "🌒";
        else if (age < 9.22831) return "🌓";
        else if (age < 12.91963) return "🌔";
        else if (age < 16.61096) return "🌕";
        else if (age < 20.30228) return "🌖";
        else if (age < 23.99361) return "🌗";
        else if (age < 27.68493) return "🌘";
        return "🌑";
    };
    const getLunarPercent = () => {
        let lunarAge = getLunarAge()
        if (lunarAge > 14.7652944265){
            return ((29.530588853 - lunarAge) / 14.7652944265 * 100);
        }
        else if (lunarAge < 14.7652944265){
            return ((lunarAge / 14.7652944265) * 100);
        } 

    }
    const getTideType = (date = new Date()) =>{
        const age = getLunarAge(date);
        if (age < 1.84566 || age > 27.68493 || (age>12.91963 && age<20.30228)){
            return "Spring Tides"
        } else if (( age > 5.53699 && age < 9.22831) || (age > 20.30228 && age < 23.99361)){
            return "Neep Tides"
        }
    }

    const tideType = getTideType()
    const lunarPhase = getLunarPhase();
    const lunarEmoji = getLunarEmoji();
    let lunarPercent = getLunarPercent();
    // old lunar percent calculation lunarPercent = ((getLunarAge() % 14.7652944265) / 14.762944265) * 100;
    lunarPercent = lunarPercent.toFixed(2);

    return (
        <div className="moon-phase-div">
            <h3>{lunarPhase}</h3>
            <div className="lunar-emoji">{lunarEmoji}</div>
            <h4>{tideType}</h4>
            <h4>{lunarPercent}%</h4>
        </div>
    );
}
