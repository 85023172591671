//import useState and useEffect
import React, { useState, useEffect, Component } from "react";
import "./App.css";
import TideIcon from "./TideIcon";
import TideTable from "./TideTable";
import MoonIcon from "./MoonIcon";
import SunComponent from "./SunComponent";
import ClockComponent from "./ClockComponent";
import RadarComponent from "./RadarComponent";

//Sunrise Sunset
//Moon status

export default function App() {
    //2 Create our *dogImage* variable as well as the *setDogImage* function via useState
    //we're setting the default value of dogImage to null, so that while we wait for the
    //fetch to complete, we don't attempt to render the image
    let [weather, setWeather] = useState(null);

    //3 create our useEffect function
    //Ideas for Params to capture properties.hazards, properties.lightningActivityLevel
    useEffect(() => {
        function fetchWeather() {
            return (
                fetch("https://api.weather.gov/gridpoints/OKX/34,55/forecast") //ossining forecast
                    .then((response) => response.json())
                    //4. setting *dogImage to the image url that we received from the response above
                    .then((data) => {
                        if (data.properties) {
                            setWeather(data);
                        }
                    })
                    .catch((e) => {
                        console.log(
                            "ok there was an error with the weather data fetch, catching error here"
                        );
                        console.error(e);
                    })
            );
        }

        fetchWeather();

        const intervalId = setInterval(() => {
            fetchWeather();
        }, 11000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (weather != null) {
        //todo, maybe split tides into a seperate component, so these aren't tied together if one site goes bad on the loading.
        const periods = weather.properties.periods;
        const nextForecast = periods[0];
        const nextNextForecast = periods[1];
        const nextNextNextForecast = periods[2];

        return (
            <div className="App">
                <div className="header-box">
                    <img
                        className="obcc-logo"
                        alt="obcc logo"
                        src="/obcc_logo.png"
                    />
                    <div className="header-text-box">
                        <h1>Ossining Boat and Canoe Club</h1>
                        <h2>Official Live Weather Page - <ClockComponent/></h2>
                        
                    </div>
                    <div className="obcc-logo"></div>
                </div>

                <div className="body">
                    <div></div>

                    <div className="wtt-box">
                        <div className="utility-icon">
                            <h4>Wind from</h4>{" "}
                            <img
                                src={nextForecast.windDirection + ".svg"}
                            ></img>
                            <h3>{nextForecast.windSpeed}</h3>
                        </div>

                        
                        <div className="utility-icon">
                            <MoonIcon />
                        </div>
                        <div className="utility-icon">
                            <SunComponent />
                        </div>
                        <TideIcon />
                    </div>

                    <div className="detailed-forecasts">
                        <div className="detailed-forecast">
                            <h3>{periods[0].name}:</h3>
                            <div className="image-text">
                                <img
                                    className="weather-icon"
                                    src={periods[0].icon}
                                ></img>
                                <span> {periods[0].detailedForecast} </span>
                            </div>
                        </div>
                        <div className="detailed-forecast">
                            <h3>{periods[1].name}:</h3>
                            <div className="image-text">
                                <img
                                    className="weather-icon"
                                    src={periods[1].icon}
                                ></img>
                                <span> {periods[1].detailedForecast}</span>
                            </div>
                        </div>
                        <div className="detailed-forecast">
                            <h3>{periods[2].name}:</h3>
                            <div className="image-text">
                                <img
                                    className="weather-icon"
                                    src={periods[2].icon}
                                ></img>
                                <span> {periods[2].detailedForecast} </span>
                            </div>
                        </div>
                    </div>
                    
                    <hr></hr>
                    <RadarComponent/>
                    <TideTable />
                    
                </div>
                <div className="footer">
                        <p>
                            <i>
                                Data from NOAA.gov is provided without guarantee
                                of accuracy. Not in Ossining? Visit <a href="https://livelocalradar.com/" target="_blank" rel="noopener noreferrer">
                                Livelocalradar.com </a> for a custom dashboard of your towns weather and radar. Reach out to Gabriel via
                                phone or email to
                                report any issues with this website.
                            </i>
                        </p>
                    </div>
            </div>
        );
    } else
        return (
            <div className="App">
                <img width="125px" alt="" src="/obcc_logo.png" />
                <h1>Ossining Boat and Canoe Club</h1> <h1></h1>
                <h3>Loading data from NOAA site</h3>
            </div>
        );
}
